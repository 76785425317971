/* eslint consistent-return: off */
let cachedSupported;

export const supported = () => {
  if (cachedSupported === undefined) {
    // https://github.com/Modernizr/Modernizr/blob/e2c27dcd32d6185846ce3c6c83d7634cfa402d19/feature-detects/storage/localstorage.js
    const testItem = 'localStorageSupport';

    try {
      localStorage.setItem(testItem, testItem);
      localStorage.removeItem(testItem);
      cachedSupported = true;
    } catch (e) {
      cachedSupported = false;
    }
  }

  return cachedSupported;
};

export const get = (key) => {
  if (!supported()) return;

  const value = localStorage.getItem(key);

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const set = (key, value) => {
  if (!supported()) return false;

  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    return false;
  }

  return true;
};

export const remove = (key) => {
  if (!supported()) return;

  localStorage.removeItem(key);
};

export const clear = () => {
  if (supported() && localStorage.length) {
    localStorage.clear();
  }
};

export const keys = () => {
  if (!supported()) return [];

  return Object.keys(localStorage);
};
