import { getJSON } from 'bv-fetch';
import { loggedIn } from 'Helpers/session';

let featuresMap = {};
const promisesMemo = {};
let asyncRequestPromise;

const setFeatures = (features) => {
  featuresMap = features.reduce((acc, feature) => {
    acc[feature.name] = feature;
    return acc;
  }, {});
};

setFeatures(window.features || []);

const isEnabled = (featureName) => (
  featuresMap[featureName]?.enabled || false
);

const doAsync = (featureName) => (
  loggedIn && loggedIn() && featuresMap[featureName] && featuresMap[featureName].has_users
);

const asyncRequest = () => {
  asyncRequestPromise = asyncRequestPromise || new Promise((resolve) => {
    getJSON('/api/user_features.json')
      .then(setFeatures)
      .finally(resolve);
  });

  return asyncRequestPromise;
};

const getPromise = (featureName) => {
  promisesMemo[featureName] = promisesMemo[featureName] || new Promise((resolve) => {
    if (isEnabled(featureName)) {
      resolve(true);
    } else if (doAsync(featureName)) {
      asyncRequest().then(() => { resolve(isEnabled(featureName)); });
    } else {
      resolve(false);
    }
  });

  return promisesMemo[featureName];
};

const guard = (featureName, enabledCallback, disabledCallback) => {
  const promise = getPromise(featureName);

  if (enabledCallback || disabledCallback) {
    promise.then((enabled) => {
      if (enabled) {
        enabledCallback();
      } else if (disabledCallback) {
        disabledCallback();
      }
    });
  }

  return promise;
};

export { guard, isEnabled as isGloballyEnabled };
