import PropTypes from 'prop-types';
import classnames from 'classnames';

const ToggleIcon = ({
  light,
  folded,
  toggleFolded,
  big,
  solid,
}) => {
  const className = classnames('bvs-icon', {
    'is-inverted': light,
    'is-big': big,
  }, `is-arrow${solid ? '-solid' : ''}-circle-${folded ? 'down' : 'up'}`);

  return <span className={className} onClick={toggleFolded} />;
};

ToggleIcon.propTypes = {
  folded: PropTypes.bool.isRequired,
  light: PropTypes.bool.isRequired,
  toggleFolded: PropTypes.func.isRequired,
  big: PropTypes.bool,
  solid: PropTypes.bool,
};

ToggleIcon.defaultProps = {
  big: false,
  solid: false,
};

export default ToggleIcon;
