import { v } from './vars';

const isActiveStorage = (path) => !!(/^cms-images\/[a-zA-Z0-9]{28}$/.test(path));

export const assetPath = (path) => (path ? `${v('assetsHost')}${path}` : '');
export const defaultAssetPath = (path) => (path ? `${v('defaultAssetsHost')}${path}` : '');

export const cmsAssetPath = (path) => {
  if (!path) return '';
  if (isActiveStorage(path)) return `${v('defaultCmsAssetsHost')}/${path}`;
  return `${v('cmsAssetsHost')}${path}`.replace(/'/g, '%27');
};

export const defaultCmsAssetPath = (path) => (path ? `${v('defaultCmsAssetsHost')}${path}` : '');
