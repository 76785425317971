import { without } from 'underscore';

/*

  Move an specific element into an array to a different position within
  the array. This function is pure and returns a new array instead of modifying
  the one given as a parameter

  If the item is not found or the index provided is outside of the array
  we will just return a new array with nothing changed

*/

export default (arr, element, index) => {
  if (arr.indexOf(element) === -1 || index > arr.length - 1) {
    return arr;
  }

  const arrWithoutElement = without(arr, element);

  return [
    ...arrWithoutElement.slice(0, index),
    element,
    ...arrWithoutElement.slice(index),
  ];
};
