import { getJSON } from 'bv-fetch';
import { accountNumber } from 'Helpers/session';

let fetchPromise;

const emptyResponse = () => ({
  acceptablePriceChangePct: 0,
  acceptLineChange: null,
});

const fetchBettingSettings = ({ force, timeout }) => (
  new Promise((resolve, reject) => {
    getJSON(
      '/bv_api/betting_api/account_settings',
      {
        l: accountNumber(),
        fetchOpts: {
          cache: force ? 'reload' : 'default',
        },
      },
    ).then(resolve).catch(reject);

    // Fetch API does not have timeout support
    // here we are rejecting the promise if it takes more than 1 second
    if (timeout) {
      setTimeout(reject, timeout);
    }
  }).catch(emptyResponse)
);

export default (opts = {}) => {
  const force = opts && opts.force;

  fetchPromise = (!force && fetchPromise) || fetchBettingSettings(opts);

  return fetchPromise;
};
