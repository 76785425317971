import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { locale } from 'bv-i18n';

const TrustPilotView = ({
  isLandingPage,
  isCashier,
  templateId,
  businessUnitId,
  url,
}) => {
  const getTheme = () => {
    const computedStyles = getComputedStyle(document.documentElement);
    if (isCashier) return computedStyles.getPropertyValue('--trustPilotCashierBg');
    if (isLandingPage) return computedStyles.getPropertyValue('--trustPilotLandingPageBg');
    return computedStyles.getPropertyValue('--trustPilotSiteBg');
  };

  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) window.Trustpilot.loadFromElement(ref.current, true);
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={locale()}
      data-template-id={templateId}
      data-businessunit-id={businessUnitId}
      data-style-height="75px"
      data-style-width="100px"
      align="center" // eslint-disable-line react/no-unknown-property
      data-theme={getTheme()}
    >
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

TrustPilotView.propTypes = {
  isLandingPage: PropTypes.bool,
  isCashier: PropTypes.bool,
  templateId: PropTypes.string.isRequired,
  businessUnitId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

TrustPilotView.defaultProps = {
  isLandingPage: false,
  isCashier: false,
};

export default TrustPilotView;
