import { getJSONWithPreload } from 'bv-fetch';
import { locale } from 'bv-i18n';
import { accountNumber } from 'bv-helpers/session';

export default (location) => (
  getJSONWithPreload('/bv_api/banners', {
    location,
    c: locale(),
    l: accountNumber(),
  }).catch(() => [])
);
