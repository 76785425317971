import { Notifier } from '@airbrake/browser';
import StackTrace from 'stacktrace-js';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary as ReactWithErrorBoundary, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

const backtraceToStackString = (trace) => trace.map((s) => s.toString()).join('\n');

const notifier = new Notifier({
  projectId: '42',
  projectKey: '42',
  remoteConfig: false,
  host: `${window.location.protocol}//${window.location.host}/frontend-error`,
  instrumentation: {
    fetch: true,
    onerror: true,
    unhandledrejection: true,
    xhr: false,
  },
});

const IGNORED_ERROR_MESSAGES = [
  'Timeout (u)', // networking
  'The string did not match the expected pattern.',
  'Load failed', // networking
  'Browser is not supported', // QM
  'Document is not fully active', // QM
  'QuantumMetricAPI is not defined', // QM
  'NetworkError when attempting to fetch resource.', // networking
  'Error',
  'The quota has been exceeded.', // QM
  'pixie is not defined', // GTM script
];

notifier.addFilter((notice) => {
  if (notice.errors.some((error) => IGNORED_ERROR_MESSAGES.includes(error.message))) {
    return null;
  }
  return notice;
});

export const notify = (error, info) => {
  try {
    StackTrace.fromError(error).then((backtrace) => {
    // eslint-disable-next-line no-param-reassign
      error.stack = backtraceToStackString(backtrace);
      notifier.notify({ error, params: { info } });
    }).catch(() => {
    // noop
    });
  } catch {
    // noop
  }
};

export const ErrorBoundary = (props) => {
  const { onError: oldOnError } = props;
  const onError = useCallback((error, info) => {
    notify(error, info);
    if (oldOnError) oldOnError(error, info);
  }, [oldOnError]);

  return <ReactErrorBoundary {...props} onError={onError} />;
};
ErrorBoundary.propTypes = {
  onError: PropTypes.func,
};
ErrorBoundary.defaultProps = {
  onError: null,
};

export const withErrorBoundary = (WrappedComponent, opts) => (
  ReactWithErrorBoundary(WrappedComponent, {
    ...opts,
    onError(error, info) {
      notify(error, info);
      if (opts.onError) opts.onError(error, info);
    },
  })
);

export default ErrorBoundary;
