/* eslint react/forbid-prop-types: 'off' */
import PropTypes from 'prop-types';

export const IEvent = PropTypes.shape({
  comments: PropTypes.array,
  date: PropTypes.string,
  description: PropTypes.string,
  epoch_date: PropTypes.number,
  ew_info: PropTypes.string,
  handicap: PropTypes.bool,
  has_stream: PropTypes.bool,
  id: PropTypes.number,
  info: PropTypes.any,
  meeting_id: PropTypes.number,
  meeting_name: PropTypes.string,
  place_terms_description: PropTypes.string,
  show_extra_info: PropTypes.bool,
  sport_id: PropTypes.number,
  str_date: PropTypes.string,
  time: PropTypes.string,
});

export const IMeeting = PropTypes.shape({
  day: PropTypes.string,
  description: PropTypes.string,
  event_path_id: PropTypes.number,
  events: PropTypes.arrayOf(IEvent),
  group: PropTypes.string,
  id: PropTypes.number,
  meeting_id: PropTypes.number,
  sport_id: PropTypes.number,
});

const TimeFrameShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.arrayOf(IMeeting),
});

export const IMeetingsRequest = PropTypes.shape({
  future: TimeFrameShape,
  today: TimeFrameShape,
  tomorrow: TimeFrameShape,
});

const IRunner = PropTypes.shape({
  age: PropTypes.number,
  comment: PropTypes.string,
  eye_catcher: PropTypes.bool,
  gender: PropTypes.oneOf(['Male', 'Bitch']),
  greyhound_id: PropTypes.string,
  opponent_id: PropTypes.number,
  outcome_id: PropTypes.number,
  performances: PropTypes.string,
  runner_name: PropTypes.string,
  star_rating: PropTypes.oneOf([1, 2, 3, 4, 5]),
  status_name: PropTypes.string,
  timeform123_place: PropTypes.string,
  trainer_full_name: PropTypes.string,
});

export const ITimeForm = PropTypes.shape({
  course_name: PropTypes.string,
  created: PropTypes.string,
  event_id: PropTypes.number,
  grade: PropTypes.number,
  meeting_id: PropTypes.number,
  race_comment: PropTypes.string,
  race_distance: PropTypes.number,
  runners: PropTypes.arrayOf(IRunner),
  start_time_gmt_scheduled: PropTypes.string,
});
