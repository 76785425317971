import { isObject, partial } from 'underscore';

const invalidForAppend = (value) => [null, undefined].includes(value);

const append = (url, key, value) => {
  if (invalidForAppend(value)) { return; }

  if (Array.isArray(value)) {
    value.forEach(partial(append, url, `${key}[]`));
  } else if (isObject(value)) {
    Object.entries(value).forEach(([keyIn, valueIn]) => append(url, `${key}[${keyIn}]`, valueIn));
  } else {
    url.searchParams.append(key, value);
  }
};

export const appendParamsToUrl = (url, params) => {
  const appendToUrl = partial(append, url);

  Object.entries(params).forEach(([key, value]) => appendToUrl(key, value));

  return url;
};

export const isURLAbsolute = (str) => {
  const urlRegex = /^(?:(?:http|https):\/\/)(?:\S+(?::\S*)?@)?(?:(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[0-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))|localhost)(?::\d{2,5})?(?:(\/|\?|#)[^\s]*)?$/;
  const url = new RegExp(urlRegex, 'i');
  return url.test(str);
};

export default {
  appendParamsToUrl,
  isURLAbsolute,
};

const trailingSlash = /\/$/;

export const withoutTrailingSlash = (string) => string.replace(trailingSlash, '');
