const expiryDate = (expiry) => {
  const dt = new Date();

  dt.setTime(dt.getTime() + expiry);

  return dt.toUTCString();
};

export const setCookie = (cookieName, cookieValue, cookiePath = '/', expiry = null) => {
  const sameSiteNoneSecure = window.location.protocol === 'https:' ? 'samesite=none; secure;' : '';

  const expiryValue = expiry
    ? `expires=${expiryDate(expiry)};`
    : '';

  document.cookie = `${cookieName}=${cookieValue}; ${sameSiteNoneSecure} ${expiryValue} path=${cookiePath}`;
};

export const getCookie = (cookieName) => {
  const cookie = document.cookie.split('; ').find((c) => (c.startsWith(`${cookieName}=`)));

  if (cookie === undefined) return null;

  return cookie.replace(`${cookieName}=`, '');
};

export const removeCookie = (cookieName, domain) => {
  const baseCookieDefinition = `${cookieName}=0; path=/; max-age=0;`;
  if (domain) {
    const hostNameParts = domain.split('.');
    const topDomain = `${hostNameParts.at(-2)}.${hostNameParts.at(-1)}`;
    document.cookie = `${baseCookieDefinition} domain=${topDomain}`;
    return;
  }
  document.cookie = baseCookieDefinition;
};

export const removePrefixedCookies = (prefix, domain) => {
  const cookieKeyPattern = new RegExp(`(${prefix}[^=]+)=`);
  document.cookie.split('; ')
    .filter((cookie) => cookie.startsWith(prefix))
    .map((cookie) => cookie.match(cookieKeyPattern)[1])
    .forEach((cookie) => removeCookie(cookie, domain));
};
