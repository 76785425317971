import { useState, useEffect } from 'react';
import { v as bvVar } from 'bv';
import PropTypes from 'prop-types';
import TrustPilotView from './trust_pilot_view';

const TrustPilotContainer = ({
  enabled,
  ...rest
}) => {
  const scriptSrc = bvVar('trustPilotScriptUrl');

  const bootTrustPilot = (cb) => {
    if (window.Trustpilot) {
      cb();
    } else {
      const scriptTag = document.createElement('script');
      scriptTag.id = 'trustpilot-snippet';
      scriptTag.src = scriptSrc;
      scriptTag.async = 'async';
      scriptTag.defer = 'defer';
      scriptTag.onload = () => cb();
      document.body.appendChild(scriptTag);
    }
  };

  const [bootedTrustPilot, setBootedTrustPilot] = useState(false);

  useEffect(() => {
    bootTrustPilot(() => setBootedTrustPilot(true));
  }, []);

  if (!bootedTrustPilot) return null;
  if (!window.Trustpilot) return null;

  return (
    <TrustPilotView
      {...rest}
    />
  );
};

TrustPilotContainer.propTypes = {
  isLandingPage: PropTypes.bool,
  enabled: PropTypes.bool.isRequired,
  templateId: PropTypes.string.isRequired,
  businessUnitId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

TrustPilotContainer.defaultProps = {
  isLandingPage: false,
};

export default TrustPilotContainer;
