export default (string) => {
  let hash = 0;

  if (string.length === 0) return hash;

  string.split('').forEach((char) => {
    /* eslint-disable no-bitwise */
    hash = ((hash << 5) - hash) + char.charCodeAt();
    hash &= hash;
  });

  return hash;
};
