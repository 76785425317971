import { lazy, Suspense } from 'react';
import { property } from 'underscore';
import { v as bvVar } from 'bv';
import { bootApp } from '../bv';
import componentDependencies from './component_dependencies';

const modulesQMDelayedStart = ['login', 'registration'];

export default (fallbackComponent = null) => (componentKey) => {
  const LazyComponent = lazy(() => {
    const {
      app,
      dependencies,
      getter,
      softDependencies,
    } = property(componentKey.split('.'))(componentDependencies);

    if (modulesQMDelayedStart.includes(componentKey) && bvVar('shouldQMStartDelayed')) {
      window.startQM();
    }

    return bootApp({
      dependencies: dependencies || [],
      softDependencies,
      app,
    }).then(getter);
  });

  const WithLazyLoad = (props) => (
    <Suspense fallback={fallbackComponent}>
      <LazyComponent {...props} />
    </Suspense>
  );

  WithLazyLoad.displayName = 'withLazyLoad';

  return WithLazyLoad;
};
