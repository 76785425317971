/* eslint no-undef: off */
import locale from '../locale';
import { translate, exist } from '../translations';

const javascriptPrefixed = (fn) => (key, ...rest) => (
  fn(`javascript.${key}`, ...rest)
);

window.BvApi = window.BvApi || {};

BvApi.i18n = {
  locale,
  translate: {
    t: javascriptPrefixed(translate),
    exist: javascriptPrefixed(exist),
  },
};

BvApi.t = BvApi.i18n.translate.t;
BvApi.t.exist = BvApi.i18n.translate.exist;
