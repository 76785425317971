export default class {
  constructor($scrollable) {
    this.$scrollable = $scrollable;
  }

  scrollTop(value) {
    if (value !== undefined) {
      this.$scrollable.scrollTop = value;
    }

    return this.$scrollable.scrollTop;
  }

  saveScroll() {
    this.scrollValue = this.scrollTop();
  }

  restoreScroll() {
    this.scrollTop(this.scrollValue);
  }
}
