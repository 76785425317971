import {
  compose, createStore, applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';

import reduxDynamicMiddlewares from './lib/redux-dynamic-middlewares';

/* eslint-disable-next-line no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  (state) => state, // Non functional reducer so createStore dont complain
  composeEnhancers(applyMiddleware(
    thunk,
    reduxDynamicMiddlewares,
  )),
);
