import {
  bulkAdd, translate as t, exist, interpolate,
} from './translations';
import locale from './locale';

require('./adapters/BvApi.t');

export {
  bulkAdd,
  t,
  exist,
  locale,
  interpolate,
};
