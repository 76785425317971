import { isObject } from 'underscore';
import { compile } from 'Vendor/assets/javascripts/jq';

const dataToPush = (data, fieldsToFilter = []) => {
  if (!fieldsToFilter.length) return data;

  if (Array.isArray(data)) {
    const obj = [...data];
    for (let index = obj.length - 1; index >= 0; index -= 1) {
      if (isObject(obj[index])) {
        obj[index] = dataToPush(obj[index], fieldsToFilter);
        if (!Object.keys(obj[index]).length) {
          obj.splice(index, 1);
        }
      } else {
        obj.splice(index, 1);
      }
    }
    return obj;
  } if (typeof data === 'object') {
    const obj = { ...data };
    Object.entries(obj).forEach(([key, value]) => {
      if (!fieldsToFilter.includes(key)) {
        if (isObject(value)) {
          obj[key] = dataToPush(value, fieldsToFilter);
          if (!Object.keys(obj[key]).length) {
            delete obj[key];
          }
        } else {
          delete obj[key];
        }
      }
    });
    return obj;
  }

  return data;
};

export const filterFields = (data, configJQ = null, fieldsToFilter = []) => {
  if (configJQ) {
    /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    try {
      const filter = compile(configJQ);
      return filter(data).next().value;
    } catch (e) { }
  }
  return dataToPush(data, fieldsToFilter);
};
