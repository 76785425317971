import { isObject } from 'underscore';
import unescape from './helpers/unescape';
import adaptTranslationObjectKeys from './helpers/adapt_translation_object_keys';

const translations = {};

const interpolateByKey = (string, obj) => (
  Object.keys(obj || {}).reduce((accum, key) => (
    accum.replace(new RegExp(`%?\\{${key}\\}`, 'g'), obj[key])
  ), string)
);

export const bulkAdd = (toAdd) => { Object.assign(translations, toAdd); };

export const translate = (key, ...rest) => {
  // BvI18n.t receives t(key: string, variables: { key: value })
  // example =>  welcome_message: "My name is %{lastname} %{firstname}."
  // BvI18n.t('welcome_message', { lastname: 'Sanchez', firstname: 'Pepe })

  const options = isObject(rest[rest.length - 1]) ? rest[rest.length - 1] : null;

  // additiona default option can be passed in case translation key does not exist
  // example => BvI18n.t('missing_key', { default: 'Fallback translation' })
  if (!(key in translations)) return (options && options.default) ? options.default : key;

  const translation = translations[key];

  if (translation.match(/%{[A-Za-z0-9_-]+\}/g)) {
    return unescape(interpolateByKey(translation, adaptTranslationObjectKeys(options)));
  }
  return unescape(translation);
};

export const exist = (key) => !!translations[key];

// Receives a string and returns a function
// Used by locale-fallback when generate JS file fails to load
export const interpolate = (string) => (...args) => interpolateByKey(string, { ...args });
