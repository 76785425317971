const snakeCaseToCamelCase = (s) => s.toLowerCase()
  .replace(/([-_])([a-z])/g, (_match, _p1, p2) => p2.toUpperCase());

const camelCaseToSnakeCase = (s) => s.split(/(?=[A-Z])/).join('_').toLowerCase();

export default (translationObj) => (
  Object.keys(translationObj || {}).reduce((acc, curr) => {
    if (/_/.test(curr)) {
      return {
        ...acc,
        [snakeCaseToCamelCase(curr)]: translationObj[curr],
      };
    }

    if (/[A-Z]/.test(curr)) {
      return {
        ...acc,
        [camelCaseToSnakeCase(curr)]: translationObj[curr],
      };
    }

    return acc;
  }, translationObj)
);
