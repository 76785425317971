export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const updateObjectInArray = (array, action) => (
  array.map((item, index) => {
    if (index === action.idx) {
      return updateObject(item, action.item);
    }
    return item;
  })
);
