import { v as bvVar } from '../../bv/vars';

import { filterFields } from './datalayer_fields_filtering';

const prepareDataDoSend = (dataToSend, processingSettings, datalayerEndpointUrl) => ({
  ...(datalayerEndpointUrl
    ? { event_name: processingSettings.event }
    : { event: processingSettings.event }
  ),
  'gtm.start': new Date().getTime(),
  request: filterFields(
    dataToSend.request,
    processingSettings.configRequestFilter,
    processingSettings.requestFields,
  ),
  ...(dataToSend.response ? {
    response: filterFields(
      dataToSend.response,
      processingSettings.configResponseFilter,
      processingSettings.responseFields,
    ),
  } : null),
});

const filterValidEventsFn = (eventType, requestUrl) => ({
  fireOn, url,
}) => eventType === fireOn && new RegExp(`^${url}`).test(requestUrl);

export const pushDatalayer = (requestType, requestUrl, eventType = 'response', data = {}) => {
  bvVar('dataLayerSettings')[requestType]?.filter(filterValidEventsFn(eventType, requestUrl)).forEach((event) => {
    /**
     * bvVar('datalayerServerSideEndpoint') - defined on SiteTranslation config level
     * event.datalayerEndpointUrl - defined on Site Datalayer configuration level
     */
    const datalayerEndpointUrl = event.datalayerEndpointUrl || bvVar('datalayerServerSideEndpoint');
    const dataToSave = prepareDataDoSend(data, event, datalayerEndpointUrl);

    if (datalayerEndpointUrl) {
      try {
        const cookies = document.cookie;
        const { origin } = new URL(window.location);

        const serverEndpointURL = new URL(datalayerEndpointUrl);
        const gtmDebug = serverEndpointURL.searchParams.get('gtm_debug') || '';
        serverEndpointURL.searchParams.delete('gtm_debug');

        fetch(serverEndpointURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json, text/plain, */*',
            Accept: 'application/json',
            Cookies: cookies,
            Origin: origin,
            'Set-Cookie': cookies,
            ...(gtmDebug && { 'X-Gtm-Server-Preview': gtmDebug }),
          },
          body: JSON.stringify(dataToSave),
          credentials: 'same-origin',
        });
      } catch (error) {
        //
      }
    } else {
      window.dataLayer.push(dataToSave);
    }
  });
};
